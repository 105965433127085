/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    h2: "h2",
    div: "div",
    img: "img",
    em: "em"
  }, _provideComponents(), props.components), {TableOfContents, LandingPageCta, StateCta} = _components;
  if (!LandingPageCta) _missingMdxReference("LandingPageCta", true);
  if (!StateCta) _missingMdxReference("StateCta", true);
  if (!TableOfContents) _missingMdxReference("TableOfContents", true);
  return React.createElement(React.Fragment, null, React.createElement(TableOfContents, {
    tableOfContents: props.data.mdx.tableOfContents
  }), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "When you purchase a ", React.createElement(_components.a, {
    href: "/hearing-aids/",
    className: "c-md-a"
  }, "hearing aid device"), ", you’re not only investing in your hearing, you are making an investment toward your overall health and quality of life. Additionally, those closest to you – your spouse, children, neighbors, and colleagues – will benefit from your improved hearing. For example, your wife or husband won’t ask you to lower the volume on the TV anymore, nor will you have to read your friend’s lips to understand what she’s saying. When you decide to treat your hearing loss with hearing aids, you are making the decision to live life with ease and comfort."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Depending on your lifestyle, a pair of ", React.createElement(_components.a, {
    href: "/hearing-aids/prices/",
    className: "c-md-a"
  }, "hearing aids can cost"), " anywhere between $1,599 for a basic model and $6,499 for premium. In other words, what determines the price is the technology level. If you have a more active lifestyle, you’ll want a more sophisticated hearing aid. However, price should not be a determining factor for whether or not you decide to invest in better hearing."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "health-insurance-coverage-for-hearing-aids",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#health-insurance-coverage-for-hearing-aids",
    "aria-label": "health insurance coverage for hearing aids permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Health Insurance Coverage for Hearing Aids"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Full or partial financial coverage for hearing aids through your health insurance is possible, depending on your individual policy. Additionally, depending on where you live, you may be eligible to receive help from the Northwest Lions Foundation. If you are unsure about the details of your insurance plan and what benefits you have, we can have one of our in-house insurance verification specialists check for you."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "For more information on health insurance and hearing aid benefits, head over to our health insurance page."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.img, {
    src: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/us_insurance-coverage.jpg",
    alt: "Insurance coverage by state",
    className: "c-md-img"
  })), "\n", React.createElement(LandingPageCta, {
    copy: "START MY RISK-FREE TRIAL",
    classes: "c-cta--content"
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "financing-options-for-hearing-aids",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#financing-options-for-hearing-aids",
    "aria-label": "financing options for hearing aids permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Financing Options for Hearing Aids"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "“I need a hearing aid but can’t afford it”"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "If your insurance does not offer enough coverage for hearing aids, your next step is to consider financing, which allows you to distribute the cost of your devices evenly in convenient monthly payments.  We offer financing from Allegro and CareCredit."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Anyone can apply for our flexible financing, which is subject to credit approval by Synchrony Bank. We want to make sure that price doesn’t get in the way of improving your quality of life.  Our hearing aid experts can answer any questions related to health insurance and financing.  Don’t hesitate to contact us at any time to help finance your hearing aids."), "\n", React.createElement(StateCta, {
    backgroundColor: "white"
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "invest-in-better-hearing-today",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#invest-in-better-hearing-today",
    "aria-label": "invest in better hearing today permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Invest in Better Hearing Today"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Our goal is to make sure you get the most out of your investment in better hearing. Our experts work closely with you to find a solution that not only fits your lifestyle, but also your budget. That’s why we offer free, personal phone consultations with hearing aid experts, so you can learn about obtaining financial assistance for hearing aids. In fact, if you purchase hearing aids through hear.com, you get a 45-day trial period to try your new devices, 100% risk free! If you are not satisfied, simply exchange them for a different pair or return them within the 45 days to get a full refund. There are no hidden fees, charges, or risks."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "So, why wait? Invest in better hearing and an improved quality of life today by filling out the form on this page, or calling us directly. We’re more than happy to assist you with your financial needs and get you on the path to better hearing. We are 100% committed to finding the best financial resources to help you pay for your perfect pair of hearing aids. Rest assured, if you have the desire to hear, we will make it affordable for you."), "\n", React.createElement(LandingPageCta, {
    copy: "START HEARING BETTER NOW!",
    classes: "c-cta--content"
  }), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.em, null, "*Subject to credit approval by Synchrony Bank")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
